'use client';
import {GenericContainer} from "@/components/generic/GenericContainer";
import {GenericTitle} from "@/components/generic/GenericTitle";
import {GenericTabMenu} from "@/components/generic/GenericTabMenu";
import React from "react";
import FeatureComponent, {FeatureComponentProps} from "@/components/feature/FeatureComponent";
import {useTranslations} from "next-intl";

export function IndustryComponent() {
	const [activeTab, setActiveTab] = React.useState(0);
	const t = useTranslations('');

	const industries: FeatureComponentProps[] = [
		{
			title: t("industries.banking.title"),
			image: "/industries/Banking.png",
			icon: "banking",
			description: t("industries.banking.description"),
			button2Title: t("industries.banking.button2Title"),
			button2Href: "/industries/banking",
			containerClassName: "max-w-container xl:px-0 xl:py-0 !pt-9"
		},
		{
			title: t("industries.paymentFacilitator.title"),
			image: "/industries/PF.png",
			icon: "payment-facilitator",
			description: t("industries.paymentFacilitator.description"),
			button2Title: t("industries.paymentFacilitator.button2Title"),
			button2Href: "/industries/payment-facilitator",
			containerClassName: "max-w-container xl:px-0 xl:py-0 !pt-9"
		},
		{
			title: t("industries.eCommerce.title"),
			image: "/industries/Ecommerce.png",
			icon: "e-commerce",
			description: t("industries.eCommerce.description"),
			button2Title: t("industries.eCommerce.button2Title"),
			button2Href: "/industries/e-commerce",
			containerClassName: "max-w-container xl:px-0 xl:py-0 !pt-9"
		},
		{
			title: t("industries.travelAndTicketing.title"),
			image: "/industries/Travel&Ticketing.png",
			icon: "travel-and-ticketing",
			description: t("industries.travelAndTicketing.description"),
			button2Title: t("industries.travelAndTicketing.button2Title"),
			button2Href: "/industries/travel-and-ticketing",
			containerClassName: "max-w-container xl:px-0 xl:py-0 !pt-9"
		},
		{
			title: t("industries.bnpl.title"),
			image: "/industries/BNPL.png",
			icon: "bnpl",
			description: t("industries.bnpl.description"),
			button2Title: t("industries.bnpl.button2Title"),
			button2Href: "/industries/bnpl",
			containerClassName: "max-w-container xl:px-0 xl:py-0 !pt-9"
		}
	];

	const onActiveTabChange = (index: number) => {
		setActiveTab(index);
	};

	return (
		<GenericContainer className={"bg-white justify-center text-center grid gap-6 xl:gap-[52px]"}>
			<GenericTitle title={t("industries.title")} description={t("industries.description")}/>
			<GenericContainer className={"!px-0 !py-0 h-20 gap-8 flex flex-col text-start justify-center items-center"}>
				<GenericTabMenu titles={industries.map((item: FeatureComponentProps)=> item.title || "")} icons={industries.map((item: FeatureComponentProps) => item.icon)} onChange={onActiveTabChange}/>
				<FeatureComponent
					{...industries[activeTab]}
				containerClassName={"max-w-container xl:!px-0 !py-0 !pt-0 xl:!pt-9 flex-col-reverse"}
				/>
			</GenericContainer>
		</GenericContainer>
	);
}
